<template>
  <template v-if="isUserLoaded">
    <div
      class="account-dropdown"
      :class="{
        'account-dropdown--opened': isOpen,
        'account-dropdown--buyer': true,
      }"
      @click="isOpen = !isOpen"
    >
      <div class="account-dropdown__button">
        <user-avatar :name="userName ? userName : userEmail || ''" />
      </div>
      <transition name="fade-down">
        <template v-if="isOpen">
          <div class="account-dropdown__menu">
            <div class="account-dropdown__item-list">
              <div class="account-dropdown__item">
                {{ accountName }}
                <br />
                <span class="account-dropdown__website-link">
                  {{ accountWebsite }}
                </span>
              </div>
            </div>

            <router-link
              class="account-dropdown__link"
              :to="routes.account.info"
            >
              Account
            </router-link>

            <div class="account-dropdown__signout" @click="signOut">
              <sign-out-icon class="account-dropdown__signout-icon" />
              <span class="account-dropdown__signout-text">Log out</span>
            </div>
          </div>
        </template>
      </transition>
    </div>
    <div
      v-if="isOpen"
      class="account-dropdown__clickaway"
      @click="isOpen = false"
    />
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import {
  useCurrentAccountInfo,
  useCurrentAdminInfo,
  useCurrentUserInfo,
  useSignOut,
} from '@/composable';
import { routes } from '@/router/routes';
import SignOutIcon from '@/assets/icons/sign-out.svg';
import { useAccountStore } from '@/store/modules/account/account-store';
import UserAvatar from '@/components/UI/UserAvatar.vue';

export default defineComponent({
  name: 'AccountDropDown',
  components: {
    UserAvatar,
    SignOutIcon,
  },
  setup() {
    const isOpen = ref(false);
    const { signOut } = useSignOut();
    const { accountWebsite, accountName } = useCurrentAccountInfo();

    const { adminAccounts } = useCurrentAdminInfo();

    const { isUserLoaded } = useCurrentUserInfo();

    const accountStore = useAccountStore();
    const userName = computed(() => accountStore.getCurrentUser()?.name);
    const userEmail = computed(() => accountStore.getCurrentUser()?.email);

    return {
      adminAccounts,
      userName,
      userEmail,
      accountName,
      accountWebsite,
      routes,
      isOpen,
      signOut,
      isUserLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define account-dropdown

@import '@/styles/animations';
@import '@airgrid/components/styles/shared/typography';

.account-dropdown {
  @extend %generic-text-styles;

  display: flex;
  align-items: center;
  position: relative;
  z-index: var(--z-dropdown);

  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

  cursor: default;
}

.account-dropdown__button {
  display: flex;
  align-items: center;
  color: var(--color-text-black-02);
  cursor: pointer;
  transition: var(--transition-time-default);
  &:hover {
    color: var(--color-primary-blue);
  }
}

.account-dropdown__menu {
  min-width: 157px;
  position: absolute;
  border-radius: var(--border-radius-element);
  background-color: var(--color-blacks-white);
  box-shadow: var(--shadow-dropdown);
  line-height: 16px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  box-sizing: border-box;
  top: 34px;
  right: 0;
}

.account-dropdown__item-list {
  background-color: var(--color-blacks-grey-80);
  border-radius: var(--border-radius-element);
  color: var(--color-text-black);
  font-weight: 500;
  text-decoration: none;
  display: flex;
  flex-flow: column;
  padding: 8px 0;
}

.account-dropdown__item {
  display: flex;
  flex-flow: column;
  padding: 4px 12px;
}

.account-dropdown__website-link {
  margin: 2px 0;
  color: var(--color-text-black-02);
}

.account-dropdown__link {
  color: var(---color-text-black);
  text-decoration: none;
  padding: 8px 12px;
  transition: var(--transition-time-default);
  &:hover {
    color: var(--color-primary-blue);
  }
}

.account-dropdown__signout {
  padding: 8px 12px;
  border-top: 1px solid var(--color-blacks-grey-60);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--color-text-black);
  cursor: pointer;
  &:hover {
    color: var(--color-primary-blue);
    .account-dropdown__signout-icon {
      color: var(--color-primary-blue);
    }
  }
}

.account-dropdown__signout-text {
  transition: var(--transition-time-default);
  margin-left: 8px;
}

.account-dropdown__signout-icon {
  width: 14px;
  height: 16px;
  color: var(--color-text-black-02);
  transition: var(--transition-time-default);
}

.account-dropdown__clickaway {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: default;
}
</style>
