import { apiClient } from '@/store/api/client';

interface BackendVersionAndStatus {
  version: string;
  servicesStatus: [{ name: string; isUp: boolean }];
}

export const getBackendVersionAndServicesStatus =
  async (): Promise<BackendVersionAndStatus> => {
    const { data } = await apiClient.get<BackendVersionAndStatus>({
      url: '/status',
      retryOnFail: true,
    });
    return data;
  };
