<template>
  <div class="user-avatar" data-testid="account-dropdown-user-avatar">
    {{ name[0] }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserAvatar',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
// @define user-avatar

@import '@airgrid/components/styles/shared/typography';

.user-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;

  background-color: var(--color-text-black-02);
  color: var(--color-white);

  transition: opacity var(--transition-time-default);

  opacity: 0.7;

  &:before {
    position: absolute;
    content: '';
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid var(--color-text-black-04);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    z-index: 1;
    transition: border-color var(--transition-time-default);
  }

  &:hover {
    opacity: 1;
  }
}
</style>
