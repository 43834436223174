import { AxiosRequestConfig } from 'axios';
import {
  apiClient,
  isAxiosConnectionAbortedError,
  isAxiosError,
  isAxiosRequestAbortedError,
} from '@/store/api/client';
import {
  ActivationMethod,
  AdSize,
  Audience,
  AudienceDefinition,
  AudienceDefinitionType,
  AudienceForecast,
  AudienceScale,
  AudienceStatus,
  AudienceType,
  BuyerPlatform,
  BuyerPlatformType,
  CustomAudienceQueryResults,
  Deal,
  DealMetric,
  DeviceType,
  Geography,
  MediaType,
} from 'shared-types';
import { Language } from '@/utils/language';
import { AxiosConnectionAbortedError, RequestAborted } from '@/store/errors';
import { audienceErrorsGuard } from '@/store/errors/audiences/guards';
import { AudienceErrorCodes } from '@/store/errors/audiences/errors';
import * as AudienceErrors from '@/store/errors/audiences/errors';
import {
  DefinitionInput,
  UpdateDealRequest,
} from '@/store/modules/audiences/audience-store';

export const getTagSuggestionsAndSimilarUrls = async ({
  accountId,
  queries,
  language,
  location,
  abortSignal,
}: {
  accountId: string;
  queries: {
    type: AudienceDefinitionType;
    value: string;
  }[];
  language: Language;
  location: string;
  abortSignal?: AbortSignal;
}): Promise<CustomAudienceQueryResults> => {
  try {
    const { data } = await apiClient.get<CustomAudienceQueryResults>({
      url: '/audiences/define',
      options: {
        cache: {
          ignoreCache: false,
        },
        params: {
          accountId,
          queries,
          language,
          location,
        },
        timeout: 120000,
        signal: abortSignal,
      },
    });
    return data;
  } catch (err) {
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    } else if (isAxiosConnectionAbortedError(err)) {
      throw new AxiosConnectionAbortedError();
    } else if (audienceErrorsGuard.isTooManyRequestsError(err)) {
      throw new AudienceErrors.TooManyRequestsError(err);
    }
    throw err;
  }
};

interface GetAudienceForecastInput {
  queries: {
    id?: string;
    type: AudienceDefinitionType;
    value: string;
    clusterId?: number;
  }[];
}

export const getAudienceForecast = async (
  input: GetAudienceForecastInput
): Promise<AudienceForecast> => {
  const { data } = await apiClient.get<AudienceForecast>({
    url: `/audiences/forecast`,
    options: { params: input },
  });
  return data;
};

interface BaseDefinitionQuery {
  value: string;
  type: AudienceDefinitionType.TAG | AudienceDefinitionType.SEARCH;
}

export type TagOrSearchDefinitionQuery = BaseDefinitionQuery & {
  id?: string;
  scale: AudienceScale;
  clusterId?: number;
};

export type DefinitionQuery = TagOrSearchDefinitionQuery;

export interface CreateAudienceInput {
  accountId: string;
  audienceName: string;
  demandPlatformSeatIds: {
    buyerPlatform: BuyerPlatform;
    code: string;
  }[];
  curatorSeats: {
    platform: string;
    seatId: number;
  }[];
  metric: DealMetric;
  mediaType: MediaType;
  audienceType: AudienceType;
  geography: Geography[];
  viewabilityRate?: number;
  videoCompletionRate?: number;
  queries: DefinitionQuery[];
  audiencePlanId?: string;
  plannedAudienceId?: string;
  attributionPixelGroupId?: number;
  persona?: PersonaWithImage;
  audienceActivationMethod: AudienceActivationMethod;
}

export enum AudienceActivationMethod {
  DEALS = 'DEALS',
  SEGMENTS = 'SEGMENTS',
  EXPORT = 'EXPORT',
}

export const createAudience = async ({
  accountId,
  audienceName,
  demandPlatformSeatIds,
  curatorSeats,
  metric,
  mediaType,
  audienceType,
  geography,
  viewabilityRate,
  videoCompletionRate,
  queries,
  audiencePlanId,
  plannedAudienceId,
  attributionPixelGroupId,
  persona,
  audienceActivationMethod,
}: CreateAudienceInput): Promise<{
  deals: Deal[];
  audience: Audience;
}> => {
  try {
    const payload: CreateAudienceInput = {
      accountId,
      audienceName,
      demandPlatformSeatIds,
      curatorSeats,
      audienceType,
      metric,
      mediaType,
      geography,
      queries,
      viewabilityRate,
      videoCompletionRate,
      audiencePlanId,
      plannedAudienceId,
      attributionPixelGroupId,
      persona,
      audienceActivationMethod,
    };

    const { data } = await apiClient.post<{
      deals: Deal[];
      audience: Audience;
    }>({
      url: `/audiences?accountId=${accountId}`,
      data: payload,
      options: {
        timeout: 240000,
      },
    });
    return data;
  } catch (err) {
    if (audienceErrorsGuard.isCreateAudienceError(err)) {
      throw new AudienceErrors.CreateAudienceEntityError();
    } else if (audienceErrorsGuard.isXandrDealsCreationError(err)) {
      throw new AudienceErrors.XandrDealsCreationError(err);
    } else if (audienceErrorsGuard.isXandrSegmentsCreationError(err)) {
      throw new AudienceErrors.XandrSegmentsCreationError(err);
    } else if (audienceErrorsGuard.isCuratorSeatsNotFoundError(err)) {
      throw new AudienceErrors.CuratorSeatsNotFoundError(err);
    } else if (audienceErrorsGuard.isMediaGridSegmentCreationError(err)) {
      throw new AudienceErrors.MediaGridSegmentCreationError(err);
    } else if (audienceErrorsGuard.isPubmaticSegmentCreationError(err)) {
      throw new AudienceErrors.PubmaticSegmentCreationError(err);
    } else if (audienceErrorsGuard.isAudiencePersistenceCreationError(err)) {
      throw new AudienceErrors.AudiencePersistenceCreationError();
    } else if (audienceErrorsGuard.isNexusAudienceTrainingError(err)) {
      throw new AudienceErrors.NexusAudienceTrainingError();
    } else if (audienceErrorsGuard.isBuyerPlatformSeatNotFoundError(err)) {
      throw new AudienceErrors.BuyerPlatformSeatNotFoundError();
    } else if (
      audienceErrorsGuard.isUnauthorizedFeedOrTaxonomyAudienceCreationError(err)
    ) {
      throw new AudienceErrors.UnauthorizedFeedOrTaxonomyAudienceCreationError();
    } else if (audienceErrorsGuard.isAttributionPixelGroupNotFound(err)) {
      throw new AudienceErrors.AttributionPixelGroupNotFoundError(err);
    }
    throw err;
  }
};

export const getAudiences = async (payload: {
  accountId: string;
  type?: AudienceType;
  audienceId?: string;
  options?: AxiosRequestConfig;
}): Promise<Audience[]> => {
  const { accountId, type, audienceId, options } = payload;
  try {
    const { data } = await apiClient.get<Audience[]>({
      url: `/audiences?accountId=${accountId}`,
      options: {
        ...options,
        params: {
          type,
          audienceId,
        },
      },
      retryOnFail: true,
    });
    return data;
  } catch (err) {
    if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    } else if (audienceErrorsGuard.isAudiencesStatisticsError(err)) {
      throw new AudienceErrors.AudiencesStatisticsError(err);
    }
    throw err;
  }
};

export const updateAudienceStatus = async (
  {
    accountId,
    status,
    audienceId,
  }: { accountId: string; status: AudienceStatus; audienceId: string },
  options?: AxiosRequestConfig
): Promise<void> => {
  try {
    await apiClient.patch({
      url: '/audiences',
      data: {
        accountId,
        status,
        audienceId,
      },
      options,
    });
  } catch (err) {
    if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    }
    throw err;
  }
};

export const getAudienceDeals = async ({
  accountId,
  audienceId,
}: {
  accountId: string;
  audienceId: string;
}): Promise<Deal[]> => {
  try {
    const { data } = await apiClient.get<Deal[]>({
      url: `/deals?audienceId=${audienceId}&accountId=${accountId}`,
    });
    return data;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 400) {
      return [];
    }

    if (audienceErrorsGuard.isNoDealsForAudienceError(err)) {
      throw new AudienceErrors.NoDealsForAudienceError(err);
    } else if (audienceErrorsGuard.isAccountNotFoundError(err)) {
      throw new AudienceErrors.AccountNotFoundError(err);
    }
    throw err;
  }
};

export const updateAudienceScale = async ({
  accountId,
  audienceId,
  scale,
}: {
  accountId: string;
  audienceId: string;
  scale: AudienceScale;
}): Promise<void> => {
  try {
    await apiClient.patch({
      url: '/audiences',
      data: {
        accountId,
        scale,
        audienceId,
      },
    });
  } catch (err) {
    if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    }
    throw err;
  }
};

export const createDeals = async ({
  accountId,
  audienceId,
  demandPlatformSeatIds,
}: {
  accountId: string;
  audienceId: string;
  demandPlatformSeatIds: {
    code: string;
    buyerPlatform: BuyerPlatform;
    buyerPlatformType: BuyerPlatformType;
  }[];
}): Promise<Deal[]> => {
  try {
    const { data } = await apiClient.post<{ result: Deal[] }>({
      url: `/deals?accountId=${accountId}`,
      data: {
        accountId,
        audienceId,
        demandPlatformSeatIds,
      },
    });

    return data.result;
  } catch (err) {
    if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    } else if (audienceErrorsGuard.isXandrDealsCreationError(err)) {
      throw new AudienceErrors.XandrDealsCreationError(err);
    } else if (audienceErrorsGuard.isXandrSegmentDealsCreationError(err)) {
      throw new AudienceErrors.XandrSegmentDealsCreationError(err);
    } else if (audienceErrorsGuard.isInvalidAudienceType(err)) {
      throw new AudienceErrors.InvalidAudienceType(err);
    }
    throw err;
  }
};

export const removeAudience = async (audienceId: string): Promise<void> => {
  try {
    await apiClient.delete({ url: `/audiences?id=${audienceId}` });
  } catch (err) {
    if (audienceErrorsGuard.isDeleteNotAllowedError(err)) {
      throw new AudienceErrors.DeleteNotAllowedError(err);
    } else if (audienceErrorsGuard.isUserNotAdminError(err)) {
      throw new AudienceErrors.UserNotAdminError(err);
    } else if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    } else if (audienceErrorsGuard.isAudienceDealsNotFoundError(err)) {
      throw new AudienceErrors.AudienceDealsNotFoundError(err);
    } else if (audienceErrorsGuard.isXandrDeleteOperationError(err)) {
      throw new AudienceErrors.XandrDeleteOperationError(err);
    } else if (audienceErrorsGuard.isNexusDeleteOperationError(err)) {
      throw new AudienceErrors.NexusDeleteOperationError(err);
    }
    throw err;
  }
};

export const getFeedAdvertisers = async (
  accountId: string
): Promise<string[]> => {
  const { data } = await apiClient.get<string[]>({
    url: `/audiences/feed/advertisers?accountId=${accountId}`,
  });
  return data;
};

interface UpdateAudienceRequest {
  accountId: string;
  audienceId: string;
  newOwnerId?: string;
  geography?: Geography[];
  mediaType?: MediaType;
  metric?: DealMetric;
  status?: AudienceStatus;
  deviceTypes?: DeviceType[] | null;
  adSizes?: AdSize[] | null;
  viewabilityRate?: number | null;
  videoCompletionRate?: number | null;
  definition?: {
    add: DefinitionInput[];
    remove: Omit<AudienceDefinition, 'scale' | 'clusterId'>[];
  };
}

export const updateAudience = async (
  payload: UpdateAudienceRequest
): Promise<void> => {
  try {
    await apiClient.patch({
      url: '/audiences',
      data: { ...payload },
    });
  } catch (err) {
    if (audienceErrorsGuard.isAudienceDefinitionScaleIsNotTheSame(err)) {
      throw new AudienceErrors.AudienceDefinitionScaleIsNotTheSame();
    }
    throw err;
  }
};

export const updateDeal = async (payload: UpdateDealRequest): Promise<void> => {
  const { dealId, ...data } = payload;
  await apiClient.patch({
    url: `/deals/${dealId}`,
    data,
  });
};

export interface AudienceDefinitionResponse {
  id?: string;
  definition: AudienceDefinition[];
}

export const getAudienceDefinition = async (
  audienceId: string,
  accountId: string
): Promise<AudienceDefinitionResponse> => {
  try {
    const { data } = await apiClient.get<AudienceDefinitionResponse>({
      url: '/audiences/definition',
      options: {
        params: {
          accountId,
          id: audienceId,
        },
      },
    });
    return data;
  } catch (err) {
    if (audienceErrorsGuard.isAudienceDefinitionNotFound(err)) {
      throw new AudienceErrors.AudienceDefinitionNotFound(audienceId);
    }
    throw err;
  }
};

export interface AudiencePlan {
  name: string;
  mediaType: MediaType;
  metric: DealMetric;
  geographies: Geography[];
  viewabilityRate?: number;
  completionRate?: number;
  persona?: (Persona & { img: string }) | null;
  queries: DefinitionQuery[];
}

export type AudiencePlanCreationPayload = { accountId: string } & AudiencePlan;
export const createAudiencePlan = async (
  input: AudiencePlanCreationPayload
): Promise<string> => {
  const { data } = await apiClient.post<{ id: string }>({
    url: '/audiences/plan',
    data: input,
  });
  return data.id;
};

export const getAudienceQueries = async (
  language: Language = Language.ENGLISH,
  includeAll?: boolean
): Promise<string[]> => {
  const { data } = await apiClient.get<string[]>({
    url: `audiences/queries`,
    options: {
      params: {
        language,
        includeAll,
      },
    },
    retryOnFail: true,
  });
  return data;
};

export interface GetAudiencePlansResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  accountId: string;
  name: string;
  plannedAudienceId: string;
  userId: string;
  mediaType: MediaType;
  metric: DealMetric;
  geographies: Geography[];
  viewabilityRate: null | number;
  completionRate: number;
  persona?: PersonaWithImage;
  isAudienceCreated: boolean;
  uniques: number;
  impressions: number;
  queries: TagOrSearchDefinitionQuery[];
}

export const getAudiencePlans = async (
  accountId: string
): Promise<GetAudiencePlansResponse[]> => {
  const { data } = await apiClient.get<{ result: GetAudiencePlansResponse[] }>({
    url: '/audiences/plans',
    options: {
      params: {
        accountId,
      },
    },
  });

  return data.result;
};

export const deleteAudiencePlan = async ({
  id,
  plannedAudienceId,
}: {
  id: string;
  plannedAudienceId: string;
}) => {
  await apiClient.delete({
    url: `/audiences/plan`,
    data: {
      id,
      plannedAudienceId,
    },
  });
};

export const updateAudienceOwner = async (
  {
    accountId,
    newOwnerId,
    audienceId,
  }: { accountId: string; newOwnerId: string; audienceId: string },
  options?: AxiosRequestConfig
): Promise<void> => {
  try {
    await apiClient.patch({
      url: `/audiences`,
      data: {
        accountId,
        newOwnerId,
        audienceId,
      },
      options,
    });
  } catch (err) {
    if (audienceErrorsGuard.isAudiencesNotFoundError(err)) {
      throw new AudienceErrors.AudiencesNotFoundError(err);
    }
    throw err;
  }
};

export const getAccountDeals = async (accountId: string): Promise<Deal[]> => {
  const { data } = await apiClient.get<Deal[]>({
    url: `/deals?&accountId=${accountId}`,
  });
  return data;
};

export const getQueryRecommendations = async (input: {
  queries: string[];
}): Promise<string[]> => {
  const { data } = await apiClient.get<string[]>({
    url: `/audiences/query-recommendations`,
    options: {
      params: input,
    },
  });
  return data;
};

export interface DealTemplate {
  id: number;
  version: number;
  mediaType: MediaType;
  metric: DealMetric;
  activationMethod: ActivationMethod;
  config: JsonObject;
}

export const getDealTemplates = async (): Promise<DealTemplate[]> => {
  const { data } = await apiClient.get<{ result: DealTemplate[] }>({
    url: `/deal-templates`,
  });
  return data.result;
};

export type JsonObject = { [Key in string]?: JsonValue };
export type JsonArray = JsonValue[];
export type JsonValue =
  | string
  | number
  | boolean
  | JsonObject
  | JsonArray
  | null;

export const createDealTemplate = async (payload: {
  mediaType: MediaType | null;
  metric: DealMetric | null;
  activationMethod: ActivationMethod | null;
  config: JsonObject;
}): Promise<DealTemplate> => {
  const { data } = await apiClient.post<{ result: DealTemplate }>({
    url: `/deal-templates`,
    data: payload,
  });
  return data.result;
};

export const updateDealTemplate = async (payload: {
  id: number;
  config: JsonObject;
}): Promise<DealTemplate> => {
  const { data } = await apiClient.patch<{ result: DealTemplate }>({
    url: `/deal-templates`,
    data: payload,
  });
  return data.result;
};

export const loadDryRunReport = async (
  templateId: number
): Promise<string | undefined> => {
  const { data } = await apiClient.get<{ result: string | undefined }>({
    url: `/deal-template/dry-run`,
    options: {
      params: {
        newTemplateId: templateId,
        isDryRun: true,
      },
    },
  });

  return data.result;
};

export const activateDealTemplate = async (templateId: number) => {
  await apiClient.post({
    url: `/deal-template/activate`,
    data: {
      templateId,
    },
  });
};

export interface DefinitionOption {
  tags: string[];
  searchTerms: string[];
}

export interface GenerateDefinitionOptionsInput {
  persona?: Persona;
  summary?: string;
  userInput?: string;
  numOptions?: number;
}

export const generateDefinitionOptions = async (
  input: GenerateDefinitionOptionsInput,
  accountId: string,
  signal?: AbortSignal
): Promise<DefinitionOption[]> => {
  const { numOptions, persona, summary, userInput } = input;
  const payload = {
    persona,
    numOptions,
    summary,
    userInput,
    accountId,
  };

  try {
    const { data } = await apiClient.post<{ result: DefinitionOption[] }>({
      url: `/audiences/definition-options`,
      data: payload,
      options: {
        timeout: 120000,
        signal,
        'axios-retry': {
          retries: 2,
          retryCondition: openaiRequestRetryCondition,
        },
      },
      retryOnFail: true,
    });

    return data.result;
  } catch (err) {
    handleOpenAiErrors(err);
    if (isAxiosRequestAbortedError(err)) {
      throw new RequestAborted();
    }
    throw err;
  }
};

export interface Persona {
  name: string;
  age: number;
  gender: 'male' | 'female';
  income: string;
  favoriteBrands: string[];
  interests: string[];
  description: string;
  ethnicity?: string;
  tags?: string[];
  searchTerms?: string[];
}

export interface PersonaWithImage extends Persona {
  img: string;
}

export const generatePersonas = async (payload: {
  userInput: string;
  accountId: string;
  country?: string;
  count?: number;
  withDefinition: boolean;
}): Promise<Persona[]> => {
  try {
    const { data } = await apiClient.post<{
      result: Persona[];
    }>({
      url: `/audiences/generate-personas`,
      data: payload,
      retryOnFail: true,
      options: {
        timeout: 120000,
        'axios-retry': {
          retries: 2,
          retryCondition: openaiRequestRetryCondition,
        },
      },
    });

    return data.result;
  } catch (err) {
    handleOpenAiErrors(err);
    throw err;
  }
};

export enum ImageStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CONTENT_POLICY_VIOLATION_ERROR = 'CONTENT_POLICY_VIOLATION_ERROR',
}

export interface PersonaImage {
  url: string | null;
  status: ImageStatus;
}

export const generatePersonaImage = async (
  persona: Persona,
  accountId: string
): Promise<string> => {
  const payload = {
    ...persona,
    accountId,
  };

  try {
    const { data } = await apiClient.post<{ result: string }>({
      url: `/audiences/generate-persona-image`,
      data: payload,
      retryOnFail: true,
      options: {
        timeout: 120000,
        'axios-retry': {
          retries: 2,
          retryCondition: openaiRequestRetryCondition,
        },
      },
    });

    return data.result;
  } catch (err) {
    handleOpenAiErrors(err);
    if (audienceErrorsGuard.isOpenAiContentPolicyViolationError(err)) {
      throw new AudienceErrors.OpenAiContentPolicyViolationError();
    }
    throw err;
  }
};

/**
 * Customized retry condition function used in OpenAI related axios requests.
 * It checks if 500 response is a specific OpenAI error and returns false to stop retry cycle.
 * This function has only OpenAI errors that should not be retried like OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR
 * error.
 *
 * Note that this condition can be added to requests using axios with 'axios-retry' applied.
 * These are all requests with `retryOnFail: true` flag.
 *
 * Default retry condition logic is located in the client class.
 * See: /platform/web/studio/src/store/api/client.ts
 *
 * @param error
 * @return `true` if request should be retried and `false` otherwise
 */
export const openaiRequestRetryCondition = (error: any) => {
  return (
    (error.response !== undefined &&
      error.response.status >= 500 &&
      ![
        AudienceErrorCodes.OPENAI_EXCEEDED_CURRENT_QUOTA_ERROR,
        AudienceErrorCodes.OPENAI_TOO_MANY_REQUESTS_ERROR,
        AudienceErrorCodes.OPENAI_INTERNAL_SERVER_ERROR,
        AudienceErrorCodes.OPENAI_CONTENT_POLICY_VIOLATION_ERROR,
      ].includes(error.response?.data?.code)) ||
    error.code === 'ECONNABORTED' ||
    error.code === 'ERR_NETWORK' ||
    (error.response === undefined && error.message === 'Network Error')
  );
};

/**
 * Handle common OpenAI errors triggered by API request.
 * It checks the error type and if this is an OpenAI error then throws a typed OpenAI error
 * that can be handled by UI components logic.
 *
 * @param error -- Error object caught on API call
 * @throws OpenAiCurrentQuotaExceededError
 * @throws OpenAiTooManyRequestsError
 * @throws OpenAiInternalServerError
 */
export const handleOpenAiErrors = (error: any) => {
  if (audienceErrorsGuard.isOpenAiCurrentQuotaExceededError(error)) {
    throw new AudienceErrors.OpenAiCurrentQuotaExceededError();
  } else if (audienceErrorsGuard.isOpenAiTooManyRequestsError(error)) {
    throw new AudienceErrors.OpenAiTooManyRequestsError();
  } else if (audienceErrorsGuard.isOpenAiInternalServerError(error)) {
    throw new AudienceErrors.OpenAiInternalServerError();
  }
};
