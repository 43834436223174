import { PublisherContact } from '@/store/api';
import { BackendError, ClientError } from '@/store/errors';

export enum PublishersErrorCodes {
  USER_MISSING_CORRECT_PERMISSION = 'USER_MISSING_CORRECT_PERMISSION',
  PUBLISHER_ALREADY_EXIST = 'PUBLISHER_ALREADY_EXIST',
  PUBLISHER_ONBOARDING_IS_NOT_ALLOWED = 'PUBLISHER_ONBOARDING_IS_NOT_ALLOWED',
  PUBLISHER_NOT_FOUND = 'PUBLISHER_NOT_FOUND',
  PUBLISHER_CONTACT_ALREADY_EXISTS = 'PUBLISHER_CONTACT_ALREADY_EXISTS',
  PUBLISHER_CONTACT_NOT_FOUND = 'PUBLISHER_CONTACT_NOT_FOUND',
}

export class PublisherAlreadyExistError extends ClientError {
  constructor(err?: BackendError) {
    super(
      PublishersErrorCodes.PUBLISHER_ALREADY_EXIST,
      err ? err.response.data.message : 'Publisher already exists.'
    );
  }
}

export class PublisherOnboardingIsNotAllowedError extends ClientError {
  constructor() {
    super(
      PublishersErrorCodes.PUBLISHER_ONBOARDING_IS_NOT_ALLOWED,
      'Publisher onboarding is allowed for the admin users or accounts with appropriate permissions.'
    );
  }
}

export class PublisherNotFoundError extends ClientError {
  constructor(id: string) {
    super(
      PublishersErrorCodes.PUBLISHER_NOT_FOUND,
      `Publisher with ID (${id}) not found.`
    );
  }
}

export class PublisherContactUpdatingIsNotAllowedError extends ClientError {
  constructor() {
    super(
      PublishersErrorCodes.USER_MISSING_CORRECT_PERMISSION,
      'Publisher contact updating is allowed for the admin users or accounts with appropriate permissions.'
    );
  }
}

export class PublisherContactDeletingIsNotAllowedError extends ClientError {
  constructor() {
    super(
      PublishersErrorCodes.USER_MISSING_CORRECT_PERMISSION,
      'Publisher contact deleting is allowed for the admin users or accounts with appropriate permissions.'
    );
  }
}

export class PublisherContactCreatingIsNotAllowedError extends ClientError {
  constructor() {
    super(
      PublishersErrorCodes.USER_MISSING_CORRECT_PERMISSION,
      'Publisher contacts creating is allowed for the admin users or accounts with appropriate permissions.'
    );
  }
}

export class PublisherContactAlreadyExistsError extends ClientError {
  constructor({ name, email, type }: PublisherContact) {
    super(
      PublishersErrorCodes.PUBLISHER_CONTACT_ALREADY_EXISTS,
      `The publisher contact with name: "${name}", email: "${email}" and type: ${type} already exists.`
    );
  }
}

export class PublisherContactNotFoundError extends ClientError {
  constructor(id: number) {
    super(
      PublishersErrorCodes.PUBLISHER_CONTACT_NOT_FOUND,
      `Publisher contact with ID (${id}) is not found.`
    );
  }
}
