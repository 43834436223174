import { apiClient } from '@/store/api/client';
import * as PublishersErrors from '@/store/errors/publishers/errors';
import { publishersErrorsGuard } from '@/store/errors/publishers/guards';

export enum PublisherStatus {
  LIVE = 'LIVE',
  CREATED = 'CREATED',
}

export enum PublisherContactType {
  COMMERCIAL = 'COMMERCIAL',
  ADOPS = 'ADOPS',
  TECHNICAL = 'TECHNICAL',
}

export interface PublisherContact {
  id?: number;
  name: string;
  type: PublisherContactType;
  email: string;
}

export type PublisherContactWithRequiredId = Omit<PublisherContact, 'id'> & {
  id: number;
};

export interface Publisher {
  accountId: string;
  name: string;
  website: string;
  apiKey: string;
  publisherIds: string[];
  xandrSellerMemberId: number;
  status: PublisherStatus;
  contacts: PublisherContact[];
}

export async function getPublishers(): Promise<Publisher[]> {
  const { data } = await apiClient.get<Publisher[]>({
    url: '/publishers',
    retryOnFail: true,
  });

  return data;
}

export interface CreatePublisherInput {
  accountId: string;
  name: string;
  website: string;
  xandrSellerMemberId: number;
  contacts: PublisherContact[];
}

export interface CreatePublisherResponse {
  accountId: string;
  name: string;
  website: string;
  xandrSellerMemberId: number;
  apiKey: string;
  publisherIds: string[];
  contacts: PublisherContact[];
}

export const createPublisher = async (
  input: CreatePublisherInput
): Promise<CreatePublisherResponse> => {
  try {
    const { data } = await apiClient.post<{
      result: CreatePublisherResponse;
    }>({
      url: `/publishers`,
      data: input,
    });

    return data.result;
  } catch (err) {
    if (publishersErrorsGuard.isPublisherAlreadyExistError(err)) {
      throw new PublishersErrors.PublisherAlreadyExistError(err);
    } else if (
      publishersErrorsGuard.isPublisherOnboardingNotAllowedError(err)
    ) {
      throw new PublishersErrors.PublisherOnboardingIsNotAllowedError();
    }
    throw err;
  }
};

export interface PublisherContactResponse {
  accountId: string;
  name: string;
  website: string;
  xandrSellerMemberId: number;
  apiKey: string;
  publisherIds: string[];
  contacts: PublisherContact[];
}

export interface CreatePublisherContactInput {
  publisherId: string;
  accountId: string;
  contact: PublisherContact;
}

export const createPublisherContact = async (
  input: CreatePublisherContactInput
): Promise<PublisherContactResponse> => {
  try {
    const { publisherId, ...inputData } = input;

    const { data } = await apiClient.post<{
      result: PublisherContactResponse;
    }>({
      url: `/publishers/${publisherId}/contacts`,
      data: inputData,
    });

    return data.result;
  } catch (err) {
    if (publishersErrorsGuard.isUserMissingCorrectPermissionError(err)) {
      throw new PublishersErrors.PublisherContactCreatingIsNotAllowedError();
    } else if (
      publishersErrorsGuard.isPublisherContactAlreadyExistsError(err)
    ) {
      throw new PublishersErrors.PublisherContactAlreadyExistsError(
        input.contact
      );
    } else if (publishersErrorsGuard.isPublisherNotFoundError(err)) {
      throw new PublishersErrors.PublisherNotFoundError(input.publisherId);
    }
    throw err;
  }
};

export interface UpdatePublisherContactInput {
  publisherId: string;
  accountId: string;
  contact: PublisherContactWithRequiredId;
}

export const updatePublisherContact = async (
  input: UpdatePublisherContactInput
): Promise<PublisherContactResponse> => {
  try {
    const { publisherId, ...inputData } = input;

    const { data } = await apiClient.patch<{
      result: PublisherContactResponse;
    }>({
      url: `/publishers/${publisherId}/contacts`,
      data: inputData,
    });

    return data.result;
  } catch (err) {
    if (publishersErrorsGuard.isUserMissingCorrectPermissionError(err)) {
      throw new PublishersErrors.PublisherContactUpdatingIsNotAllowedError();
    } else if (publishersErrorsGuard.isPublisherContactNotFoundError(err)) {
      throw new PublishersErrors.PublisherContactNotFoundError(
        input.contact.id
      );
    } else if (publishersErrorsGuard.isPublisherNotFoundError(err)) {
      throw new PublishersErrors.PublisherNotFoundError(input.publisherId);
    }
    throw err;
  }
};

export interface DeletePublisherContactInput {
  publisherId: string;
  accountId: string;
  contactId: number;
}

export const deletePublisherContact = async (
  input: DeletePublisherContactInput
): Promise<PublisherContactResponse> => {
  try {
    const { publisherId, ...inputData } = input;

    const { data } = await apiClient.delete<{
      result: PublisherContactResponse;
    }>({
      url: `/publishers/${publisherId}/contacts`,
      data: inputData,
    });

    return data.result;
  } catch (err) {
    if (publishersErrorsGuard.isUserMissingCorrectPermissionError(err)) {
      throw new PublishersErrors.PublisherContactDeletingIsNotAllowedError();
    } else if (publishersErrorsGuard.isPublisherContactNotFoundError(err)) {
      throw new PublishersErrors.PublisherContactNotFoundError(input.contactId);
    } else if (publishersErrorsGuard.isPublisherNotFoundError(err)) {
      throw new PublishersErrors.PublisherNotFoundError(input.publisherId);
    }
    throw err;
  }
};
