import { BackendError, isBackendError } from '@/store/errors';
import { PublishersErrorCodes } from '@/store/errors/publishers/errors';

export const isPublisherAlreadyExistError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === PublishersErrorCodes.PUBLISHER_ALREADY_EXIST
  );
};

export const isPublisherOnboardingNotAllowedError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      PublishersErrorCodes.PUBLISHER_ONBOARDING_IS_NOT_ALLOWED
  );
};

export const isPublisherNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code === PublishersErrorCodes.PUBLISHER_NOT_FOUND
  );
};

export const isUserMissingCorrectPermissionError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      PublishersErrorCodes.USER_MISSING_CORRECT_PERMISSION
  );
};

export const isPublisherContactNotFoundError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      PublishersErrorCodes.PUBLISHER_CONTACT_NOT_FOUND
  );
};

export const isPublisherContactAlreadyExistsError = (
  error: unknown
): error is BackendError => {
  return (
    isBackendError(error) &&
    error.response.data.code ===
      PublishersErrorCodes.PUBLISHER_CONTACT_ALREADY_EXISTS
  );
};

export const publishersErrorsGuard = Object.freeze({
  isPublisherAlreadyExistError,
  isPublisherOnboardingNotAllowedError,
  isPublisherNotFoundError,
  isUserMissingCorrectPermissionError,
  isPublisherContactAlreadyExistsError,
  isPublisherContactNotFoundError,
});
