import { UserInfo } from 'shared-types';

export enum SignInProvider {
  GOOGLE = 'google',
}

export enum Authenticator {
  EMAIL = 'EMAIL',
  SOCIAL = 'SOCIAL',
}

export enum OnUserChangeCallbackEventType {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  USER_UPDATED = 'USER_UPDATED',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type OnUserChangeCallback = (
  event: OnUserChangeCallbackEventType,
  user: UserInfo | null
) => void | Promise<void>;

export interface Auth {
  signOut(): Promise<void>;
  getUser(): Promise<UserInfo | null>;
  reloadUser(): Promise<void>;
  getAccessToken(): string | null;
  refreshAccessToken(): Promise<string | null>;
  onUserChange(cb: OnUserChangeCallback): void;
  initializeAuthProvider(cb: () => void): Promise<void>;
  waitForAuthIsReady(): Promise<void>;
  waitForAuthStateChange(): Promise<void>;
}
