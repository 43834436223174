import { addDays, addMinutes, startOfDay } from 'date-fns';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _sumBy from 'lodash/sumBy';
import html2canvas from 'html2canvas';
import {
  ActivationMethod,
  AudienceStatistic,
  OverviewFinancialAnalyticsItem,
} from 'shared-types';
export * from './logger';

export * from '@airgrid/components/utils';
import { formatDateString, formatDate } from '@airgrid/components/utils';

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export const formatDaysAgo = (daysAgo: number, longForm = false): string =>
  formatDate(new Date(Date.now() - DAY_IN_MILLISECONDS * daysAgo), longForm);

// Displays a custom validation message for an element
export const showCustomValidity = (
  element: HTMLInputElement,
  message: string
): void => {
  element.setCustomValidity(message);
  element.reportValidity();
  element.setCustomValidity(''); // Resets it for other validation messages to show
};

export const scrollToPosition = (y: number, x = 0): void => {
  window.scrollTo({
    top: y,
    left: x,
    behavior: 'smooth',
  });
};

export const scrollToElement = (el: Element): void => {
  const refElementMarginTop = 80;
  scrollToPosition(
    el.getBoundingClientRect().top + window.scrollY - refElementMarginTop
  );
};

export const groupBy = <T>(
  list: T[],
  keyGetter: ((item: T) => unknown) | keyof T
): Record<string, T[]> => {
  return _groupBy(list, keyGetter);
};

export const getGroupedByKeyValues = <T>(
  list: T[],
  keyGetter: ((item: T) => string) | keyof T
): T[][] => {
  return Object.values(_groupBy(list, keyGetter));
};

export const isIncludedInPeriod = (period: number, date: string): boolean => {
  let d = new Date();
  d = startOfDay(d);
  d = addMinutes(d, -d.getTimezoneOffset());
  d = addDays(d, -period);
  return new Date(date) > d;
};

export const getDaysAgoTimestamp = (daysAgo: number): number => {
  const d = new Date();
  d.setDate(d.getDate() - daysAgo);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.valueOf();
};

export function saveStringToFile({
  data,
  filename,
}: {
  data: string;
  filename: string;
}): void {
  const blob = new Blob([data], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  saveDataUrlToFile({
    filename,
    dataUrl: url,
  });
}

export function saveDataUrlToFile({
  dataUrl,
  filename,
}: {
  dataUrl: string;
  filename: string;
}): void {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.setAttribute('download', filename);
  link.click();
}

/**
 * Converts ImageData to a PNG data URL.
 * Creates an off-screen canvas, draws the provided image data onto it,
 * and then converts the canvas content to a PNG data URL for export.
 *
 * @param imgData - The image data to be drawn onto the canvas.
 * @param width - Width of the output image in pixels.
 * @param height - Height of the output image in pixels.
 * @returns A PNG data URL of the rendered image, or null if context initialization fails.
 */
const getImageUrl = (
  imgData: ImageData,
  width: number,
  height: number
): string | null => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (ctx) {
    canvas.width = width;
    canvas.height = height;
    ctx.putImageData(imgData, 0, 0);
    return canvas.toDataURL('image/png');
  }
  return null;
};

/**
 * Converts an HTML element selected by a CSS selector into a PNG data URL.
 * Useful for capturing and exporting UI sections as images.
 *
 * @param selector - CSS selector for the target HTML element.
 * @param options - Optional configuration for customizing capture.
 *   - ignore: Array of class names to exclude specific elements from capture.
 *   - inset: Number of pixels to inset the capture area, trimming the edges.
 *   - scrollX and scrollY: Custom scroll positions to adjust viewport.
 * @returns A promise resolving to a PNG data URL of the captured area, or null if unsuccessful.
 */
export const htmlToDataUrl = async (
  selector: string,
  options?: {
    ignore?: string[];
    inset?: number;
    scrollX?: number;
    scrollY?: number;
  }
): Promise<string | null> => {
  const element = document.querySelector(selector);
  if (!element) {
    return null;
  }
  const node: HTMLElement = element as HTMLElement;

  const canvas = await html2canvas(document.body, {
    ignoreElements(element: Element): boolean {
      return (options?.ignore ?? []).includes(element.className);
    },
    scale: 2,
    scrollX: 0,
    scrollY: -window.scrollY,
    onclone: (el) => {
      // NOTE: this fixes text alignment issue during exporting HTML block to PNG using html2canvas
      // Please, add data-html2canvas-shift-down-fix attribute to the text block where is required to fix alignment issues
      // https://github.com/niklasvh/html2canvas/issues/2107
      el.querySelectorAll('[data-html2canvas-shift-down-fix]').forEach(
        (element) => {
          (element as HTMLElement).style.transform = 'translateY(-1px)';
        }
      );
    },
  });
  const inset = options?.inset ?? 0;
  const context = canvas.getContext('2d');

  if (context !== null) {
    const rect = node.getBoundingClientRect();
    const data = context.getImageData(
      rect.left * window.devicePixelRatio + inset,
      rect.top * window.devicePixelRatio + inset,
      rect.width * window.devicePixelRatio,
      rect.height * window.devicePixelRatio
    );

    const dataUrl = getImageUrl(
      data,
      rect.width * window.devicePixelRatio - inset * 2,
      rect.height * window.devicePixelRatio - inset * 2
    );
    return dataUrl;
  }
  return null;
};

export const applySimpleMovingAverage = <T extends { y: number }>(
  arr: T[],
  window: number
): T[] => {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    const start = Math.max(0, i - window + 1);
    const end = Math.min(i + 1, arr.length);
    const sum = arr.slice(start, end).reduce((a, b) => a + b.y, 0);
    result.push({
      ...arr[i],
      y: sum / (end - start),
    });
  }
  return result;
};

export const getSumRevenueOrSpendAndImpressions = (
  arr: (OverviewFinancialAnalyticsItem | AudienceStatistic)[]
): { spend: number; revenue: number; impressions: number } => {
  return {
    // TODO: I would prefer to have it return undefined
    // on missing props, but some of the dashboard components
    // dependes on it being a number
    spend: _sumBy(arr, 'spend') ?? 0,
    revenue: _sumBy(arr, 'revenue') ?? 0,
    impressions: _sumBy(arr, 'impressions') ?? 0,
  };
};

export const sortBy = _sortBy;

/**
 * Generate an array with date strings ranging starting from today to today - reportIntervalDays
 */
export const generateDateRange = (reportIntervalDays: number): string[] => {
  return Array.from(
    { length: reportIntervalDays },
    (_, k) =>
      new Date(new Date().setDate(new Date().getDate() - k))
        .toISOString()
        .split('T')[0]
  );
};

/**
 * Check if string contains substing with an ability to pass options
 */
export const includesSubstring = (
  string: string,
  substring: string,
  { caseSensitive }: { caseSensitive: boolean } = { caseSensitive: false }
): boolean => {
  if (caseSensitive) {
    return string.includes(substring);
  }
  return string.toLowerCase().includes(substring.toLowerCase());
};

export const generateDatePeriod = (reportIntervalDays: number): string => {
  return `${formatDateString(
    new Date().setDate(new Date().getDate() - reportIntervalDays)
  )} - ${formatDateString(new Date().setDate(new Date().getDate()))}`;
};

export const generateCsvData = (
  title: string,
  data: { name: string; value: number | undefined }[]
): string => {
  return data.reduce(
    (acc: string, i: { name: string; value: number | undefined }) => {
      return acc + `${i.name},$${i.value ?? 0}\n`;
    },
    title + '\n'
  );
};

export const fetchAndClearLocalStorageItem = (key: string) => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value;
};

const USER_COUNTRY_KEY = 'userCountry';
export const DEFAULT_USER_COUNTRY = 'uk';

export const saveUserCountryToLocalStorage = (
  country: string | undefined
): void => {
  if (country === undefined) {
    localStorage.removeItem(USER_COUNTRY_KEY);
  } else {
    localStorage.setItem(USER_COUNTRY_KEY, country);
  }
};

export const getUserCountryFromLocalStorage = (): string | null => {
  return localStorage.getItem(USER_COUNTRY_KEY);
};

export const formatActivationMethodName = (
  activationMethod: ActivationMethod
) => {
  return {
    [ActivationMethod.GEO]: 'Geo',
    [ActivationMethod.EDGE]: 'Edge',
    [ActivationMethod.FULLURL]: 'Full URL',
    [ActivationMethod.PARTIALURL]: 'Partial URL',
  }[activationMethod];
};
