<template>
  <div
    class="banner"
    :class="{ 'banner--visible': isVisible, 'banner--success': isSuccess }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Banner',
  props: {
    isVisible: Boolean,
    isSuccess: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
// @define banner

.banner {
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--color-text-black);
  background: linear-gradient(
      0deg,
      rgba(255, 174, 77, 0.15),
      rgba(255, 174, 77, 0.15)
    ),
    var(--color-white);

  transition: height var(--transition-default);
  height: 0;
  opacity: 0;
  overflow: hidden;

  &.banner--success {
    background: var(--color-light-green);
  }

  :deep(strong) {
    font-weight: 500;
  }

  :deep(svg) {
    margin-right: 8px;
  }

  :deep(span.action) {
    color: var(--color-primary-blue);
    cursor: pointer;
  }

  &.banner--visible {
    height: 32px;
    opacity: 1;
  }

  strong {
    font-weight: bold;
  }
}
</style>
