<template>
  <update-icon />
  <span>
    <strong>Application is out of date.</strong>
    To ensure error free usage, please
    <span
      class="action"
      data-testid="application-out-of-date-banner__action-blue"
      @click="onClick"
    >
      reload</span
    >.
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UpdateIcon from '@/assets/icons/update.svg';
import { SHOW_APP_RELOAD_BANNER } from '@/const/app';

export default defineComponent({
  name: 'ApplicationOutOfDateBanner',
  components: {
    UpdateIcon,
  },
  setup() {
    const onClick = () => {
      reload();
    };

    const reload = () => {
      sessionStorage.setItem(SHOW_APP_RELOAD_BANNER, 'true');
      location.reload();
    };

    return {
      onClick,
    };
  },
});
</script>

<style lang="scss" scoped>
// @define application-out-of-date-banner

svg {
  color: var(--color-orange);
}
</style>
