import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';
import { config } from '@/runtime-env';

export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface Notification {
  id: string;
  type: NotificationType;
  message?: string;
}

export interface NotificationOptions {
  showNotificationTime?: number;
}

const SHOW_NOTIFICATION_MS = 4000;

export const useUserInterfaceStore = defineStore('user-interface', () => {
  const smallScreenBannerClosed = ref(false);
  const showNavbar = ref(false);
  const notifications = ref<Notification[]>([]);
  const loading = ref<string[]>([]);
  const showStoreRefreshIcon = ref(false);
  const storeRefreshIntervalId = ref<NodeJS.Timeout | null>(null);
  const audienceTableSavedPage = ref<number | null>(null);

  const setAudienceTableSavedPage = (page: number | null) => {
    audienceTableSavedPage.value = page;
  };

  const removeNotificationById = (id: string) => {
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== id
    );
  };

  const initialLoadingIds = ref<string[]>([]);

  const isInitialLoading = computed(() => initialLoadingIds.value.length > 0);

  const setInitialLoading = () => {
    const id = nanoid(8);

    initialLoadingIds.value.push(id);

    return id;
  };

  const unsetInitialLoading = (id: string) => {
    initialLoadingIds.value = initialLoadingIds.value.filter(
      (loadingId) => loadingId !== id
    );
  };

  const isLoading = computed(() => loading.value.length > 0);

  const setLoading = () => {
    const id = nanoid(8);

    loading.value.push(id);

    return id;
  };

  const unsetLoading = (id: string) => {
    loading.value = loading.value.filter((loadingId) => loadingId !== id);
  };

  const showNotification = ({
    message,
    type,
    options,
  }: {
    message: string;
    type: NotificationType;
    options?: NotificationOptions;
  }) => {
    const id = nanoid(8);

    notifications.value.push({
      id,
      message,
      type,
    });

    setTimeout(() => {
      removeNotificationById(id);
    }, options?.showNotificationTime ?? SHOW_NOTIFICATION_MS);

    return id;
  };

  const setShowNavbar = (show: boolean) => {
    showNavbar.value = show;
  };

  const toggleSmallScreenBannerClosed = (status: boolean) => {
    smallScreenBannerClosed.value = status;
  };

  const setShowStoreRefreshIcon = (show: boolean) => {
    showStoreRefreshIcon.value = show;
  };

  const restartStoreRefreshInterval = () => {
    clearStoreRefreshInterval();
    startStoreRefreshInterval();
  };

  const startStoreRefreshInterval = () => {
    setShowStoreRefreshIcon(false);

    storeRefreshIntervalId.value = setInterval(() => {
      setShowStoreRefreshIcon(true);
    }, config.storeRefreshIntervalMs);
  };

  const clearStoreRefreshInterval = () => {
    setShowStoreRefreshIcon(false);

    if (storeRefreshIntervalId.value) {
      clearInterval(storeRefreshIntervalId.value);
      storeRefreshIntervalId.value = null;
    }
  };

  const rootTableSearchValue = ref('');
  const setRootTableSearchValue = (value: string) => {
    rootTableSearchValue.value = value;
  };

  const measureTableIsOnlyMineGroupsFilter = ref(false);
  const setMeasureTableIsOnlyMineGroupsFilter = (value: boolean) => {
    measureTableIsOnlyMineGroupsFilter.value = value;
  };

  return {
    smallScreenBannerClosed,
    showNavbar,
    notifications,
    loading,
    isLoading,
    showStoreRefreshIcon,
    storeRefreshIntervalId,
    audienceTableSavedPage,
    setLoading,
    unsetLoading,
    showNotification,
    setShowNavbar,
    toggleSmallScreenBannerClosed,
    setShowStoreRefreshIcon,
    startStoreRefreshInterval,
    clearStoreRefreshInterval,
    restartStoreRefreshInterval,
    setAudienceTableSavedPage,
    isInitialLoading,
    setInitialLoading,
    unsetInitialLoading,
    rootTableSearchValue,
    setRootTableSearchValue,
    measureTableIsOnlyMineGroupsFilter,
    setMeasureTableIsOnlyMineGroupsFilter,
  };
});
