export class ApplicationError extends Error {
  public code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export enum GeneralErrorCode {
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  API_ERROR = 'API_ERROR',
}

interface ErrorResponse {
  response: {
    data: {
      message: string;
      code: string;
    };
  };
}

export function hasResponseDataMessageAndCode(
  error: unknown
): error is ErrorResponse {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as ErrorResponse).response === 'object' &&
    (error as ErrorResponse).response !== null &&
    typeof (error as ErrorResponse).response.data === 'object' &&
    (error as ErrorResponse).response.data !== null &&
    typeof (error as ErrorResponse).response.data.message === 'string' &&
    typeof (error as ErrorResponse).response.data.code === 'string'
  );
}

export class UnexpectedError extends ApplicationError {
  constructor(error?: unknown) {
    if (hasResponseDataMessageAndCode(error)) {
      super(error.response.data.code, error.response.data.message);
    } else if (error instanceof Error) {
      super(GeneralErrorCode.UNEXPECTED_ERROR, error.message);
    } else {
      super(GeneralErrorCode.UNEXPECTED_ERROR, 'Unexpected error');
    }
  }
}

export class ApiError extends ApplicationError {
  constructor(message: string) {
    super(GeneralErrorCode.API_ERROR, message);
  }
}

export const isApiError = (error: unknown): error is ApiError =>
  error instanceof ApplicationError &&
  error.code === GeneralErrorCode.API_ERROR;
