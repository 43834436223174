<template>
  <router-link
    class="ag-navbar__button"
    :class="{
      'ag-navbar__button--active': isActive,
    }"
    :to="to"
  >
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavbarButton',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    isActive: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
// @define ag-navbar

.ag-navbar__button {
  border-radius: var(--border-radius-element);
  box-sizing: border-box;
  padding: 12px;

  transition: all var(--transition-time-default);
  color: var(--color-text-black-02);
  display: flex;
  gap: 10px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;

  &:hover,
  &.router-link-active.router-link-exact-active,
  &.ag-navbar__button--active {
    background-color: var(--color-blacks-grey-60);
  }

  &.router-link-active.router-link-exact-active,
  &.ag-navbar__button--active {
    color: var(--color-primary-blue);
  }
}

.ag-navbar__button--link {
  padding: 0;
  gap: 0;
  &:hover {
    color: var(--color-primary-blue);
    transition: var(--transition-time-default);
    background: none;
  }
}
</style>
