import { defineStore } from 'pinia';
import { useAccountStore } from '@/store/modules/account/account-store';
import { computed, ref } from 'vue';
import {
  AttributionPixelGroupAggregateReportsResponse,
  createAttributionPixelGroup,
  CreateAttributionPixelGroupInput,
  CreateAttributionPixelGroupResponse as AttributionPixelGroup,
  GetAttributionPixelGroupAggregateReportsRequest,
  GetAttributionPixelGroupAnalyticsResponse,
  GetAttributionPixelGroupDebugReportRequest,
  GetAttributionPixelGroupDebugReportResponse,
  GetAttributionPixelGroupResponse,
  getAttributionPixelGroups,
} from '@/store/api';
import { RequestAborted } from '@/store/errors';
import { logger } from '@/utils';
import * as api from '@/store/api';
import _maxBy from 'lodash/maxBy';

export interface PixelForm {
  id: number;
  name: string;
  priority: number;
  type: string;
}

export interface PixelPriorityOption {
  label: string;
  value: number;
}

export enum DefaultPixelName {
  LANDING_PAGE = 'Landing Page (default)',
  CONVERSION_PAGE = 'Conversion Page (default)',
}

export enum PixelType {
  LANDING_PAGE = 'LP',
  CONVERSION_PAGE = 'CP',
}

export const makeDefaultPixelForm = () => {
  return [
    {
      // NOTE: This unique identifier (ID) is essential for pixel manipulation during specific actions,
      // such as deletion and updating.
      id: 1,
      priority: 1,
      name: DefaultPixelName.LANDING_PAGE,
      type: PixelType.LANDING_PAGE,
    },
    {
      id: 2,
      priority: 2,
      name: DefaultPixelName.CONVERSION_PAGE,
      type: PixelType.CONVERSION_PAGE,
    },
  ];
};

export const useMeasureStore = defineStore('measure-store', () => {
  const accountStore = useAccountStore();

  const attributionPixelGroupCreationResult = ref<
    AttributionPixelGroup | undefined
  >(undefined);

  const attributionPixelGroups = ref<
    GetAttributionPixelGroupResponse[] | undefined
  >();
  const isAttributionPixelGroupsLoaded = ref(false);

  const pixelForm = ref<PixelForm[]>(makeDefaultPixelForm());

  const isEditDialogOpened = ref(false);

  const submitAttributionPixelGroupCreation = async (
    payload: CreateAttributionPixelGroupInput
  ) => {
    attributionPixelGroupCreationResult.value =
      await createAttributionPixelGroup({
        accountId: payload.accountId,
        name: payload.name,
        attributionDestination: payload.attributionDestination,
        expiryInDays: payload.expiryInDays,
        eventWindowInDays:
          payload.eventWindowInDays && Number(payload.eventWindowInDays),
        activationDsps: payload.activationDsps,
        attributionPixels: payload.attributionPixels,
        reportConfigurations: payload.reportConfigurations,
      });
    resetPixelForm();
  };

  const resetAttributionPixelGroupCreationResult = () => {
    attributionPixelGroupCreationResult.value = undefined;
  };

  const loadAttributionPixelGroupsAbortController = ref<
    AbortController | undefined
  >();

  const loadAttributionPixelGroups = async () => {
    if (loadAttributionPixelGroupsAbortController.value) {
      loadAttributionPixelGroupsAbortController.value.abort();
    }

    loadAttributionPixelGroupsAbortController.value = new AbortController();

    const accountId = useAccountStore().getSelectedAccountId();
    isAttributionPixelGroupsLoaded.value = false;
    try {
      attributionPixelGroups.value = await getAttributionPixelGroups(
        accountId,
        loadAttributionPixelGroupsAbortController.value.signal
      );
    } catch (err) {
      if (err instanceof RequestAborted) return;
      logger.error(err);
      throw err;
    } finally {
      isAttributionPixelGroupsLoaded.value = true;
    }
  };

  const updateAttributionPixelGroupOwner = async (
    attributionPixelGroupId: number,
    newOwnerId: string
  ) => {
    const accountId = accountStore.getSelectedAccountId();

    await api.updateAttributionPixelGroupOwner({
      accountId,
      attributionPixelGroupId,
      newOwnerId,
    });
  };

  const getAttributionPixelGroup = (
    id: number
  ): AttributionPixelGroup | undefined => {
    if (attributionPixelGroups.value) {
      return attributionPixelGroups.value.find((item) => item.id === id);
    }
  };

  const resetPixelForm = () => {
    isEditDialogOpened.value = false;
    pixelForm.value = makeDefaultPixelForm();
  };

  const addNewPixel = (pixel: PixelForm) => {
    const isPixelPriorityExists = pixelPriorityOptions.value.some(
      (item) => item.value === pixel.priority
    );

    if (!isPixelPriorityExists) {
      pixelPriorityOptions.value.push({
        label: pixel.priority.toString(),
        value: pixel.priority,
      });
    }
    pixelForm.value.push(pixel);
  };

  const updatePixel = (pixelToUpdate: PixelForm) => {
    const pixelIndex = pixelForm.value.findIndex(
      (pixel) => pixel.id === pixelToUpdate.id
    );

    if (pixelIndex !== -1) {
      pixelForm.value[pixelIndex] = {
        ...pixelForm.value[pixelIndex],
        ...pixelToUpdate,
      };
    }
  };

  const deletePixel = (pixelToDelete: PixelForm) => {
    if (pixelForm.value.length === 1) {
      return;
    }
    pixelForm.value = pixelForm.value.filter(
      (pixel) => pixel.id !== pixelToDelete.id
    );

    pixelForm.value = pixelForm.value.map((pixel) => {
      if (pixel.priority > pixelToDelete.priority) {
        return {
          ...pixel,
          id: Math.floor(Math.random() * 10000),
          priority: pixel.priority - 1,
        };
      }
      return pixel;
    });
    pixelPriorityOptions.value = getPixelPriorityOptions();
  };

  const getPixelPriorityOptions = () =>
    pixelForm.value.map((pixel) => ({
      label: pixel.priority.toString(),
      value: pixel.priority,
    }));

  const pixelPriorityOptions = ref<PixelPriorityOption[]>(
    getPixelPriorityOptions()
  );

  // NOTE: This function facilitates the exchange of priorities between pixels.
  // We use it to reassign priorities when a user attempts to create a pixel with a priority already assigned to another pixel.
  // This prevents duplicate priorities, as they must be unique.
  const swapPixelsPriority = (newPixel: PixelForm, priority: number) => {
    const pixelWithDuplicatingPriority = pixelForm.value.findIndex(
      (item) => item.priority === priority && item.id !== newPixel.id
    );
    if (pixelWithDuplicatingPriority !== -1) {
      const highestIdPixel = _maxBy(pixelForm.value, 'id');

      pixelForm.value[pixelWithDuplicatingPriority] = {
        ...pixelForm.value[pixelWithDuplicatingPriority],
        priority: newPixel.priority,
        // NOTE: It is imperative to delete the existing pixel that shares a duplicated priority and create a new one with an updated priority and ID.
        // This is because the BaseTable component will trigger re-render
        // only for  new elements in the table (with new ID).
        id: highestIdPixel
          ? highestIdPixel.id + 1
          : Math.floor(Math.random() * 10000),
      };
    }
  };

  const doesFormContainDuplicateNamesOrPriorities = computed(
    () => (key: 'name' | 'priority') => {
      const namesOrPriorities = pixelForm.value.map((pixel) =>
        key === 'name' ? pixel[key].trim() : pixel[key]
      );
      return new Set(namesOrPriorities).size !== namesOrPriorities.length;
    }
  );

  const attributionPixelGroupAnalytics =
    ref<GetAttributionPixelGroupAnalyticsResponse>();
  const isAttributionPixelGroupAnalyticsLoaded = ref(false);
  const getAttributionPixelGroupAnalytics = async (
    attributionPixelGroupId: number
  ) => {
    isAttributionPixelGroupAnalyticsLoaded.value = false;
    try {
      attributionPixelGroupAnalytics.value =
        await api.getAttributionPixelGroupAnalytics(attributionPixelGroupId);
    } finally {
      isAttributionPixelGroupAnalyticsLoaded.value = true;
    }
  };

  const isAggregateReportDialogOpened = ref(false);
  const getAttributionPixelGroupAggregatedReportDimensions = async () => {
    const accountId = accountStore.getSelectedAccountId();

    return api.getAttributionPixelGroupAggregatedReportDimensions(accountId);
  };

  const setIsAddNewAggregatedReportDialogOpened = (value: boolean) => {
    isAggregateReportDialogOpened.value = value;
  };

  const attributionPixelGroupAggregateReports =
    ref<AttributionPixelGroupAggregateReportsResponse>();
  const isAttributionPixelGroupAggregateReportsLoaded = ref(false);
  const getAttributionPixelGroupAggregateReports = async (
    input: GetAttributionPixelGroupAggregateReportsRequest
  ) => {
    isAttributionPixelGroupAggregateReportsLoaded.value = false;
    try {
      attributionPixelGroupAggregateReports.value =
        await api.getAttributionPixelGroupAggregateReports(input);
    } finally {
      isAttributionPixelGroupAggregateReportsLoaded.value = true;
    }
  };

  const attributionPixelGroupDebugReport =
    ref<GetAttributionPixelGroupDebugReportResponse>();
  const isAttributionPixelGroupDebugReportLoaded = ref(false);
  const getAttributionPixelGroupDebugReport = async (
    input: GetAttributionPixelGroupDebugReportRequest
  ) => {
    isAttributionPixelGroupDebugReportLoaded.value = false;
    try {
      attributionPixelGroupDebugReport.value =
        await api.getAttributionPixelGroupDebugReport(input);
    } finally {
      isAttributionPixelGroupDebugReportLoaded.value = true;
    }
  };

  return {
    attributionPixelGroupCreationResult,
    attributionPixelGroups,
    isAttributionPixelGroupsLoaded,
    submitAttributionPixelGroupCreation,
    resetAttributionPixelGroupCreationResult,
    loadAttributionPixelGroups,
    getAttributionPixelGroup,
    pixelForm,
    isEditDialogOpened,
    pixelPriorityOptions,
    resetPixelForm,
    addNewPixel,
    updatePixel,
    deletePixel,
    swapPixelsPriority,
    doesFormContainDuplicateNamesOrPriorities,
    updateAttributionPixelGroupOwner,
    attributionPixelGroupAnalytics,
    isAttributionPixelGroupAnalyticsLoaded,
    getAttributionPixelGroupAnalytics,
    getAttributionPixelGroupAggregatedReportDimensions,
    isAggregateReportDialogOpened,
    setIsAddNewAggregatedReportDialogOpened,
    attributionPixelGroupAggregateReports,
    isAttributionPixelGroupAggregateReportsLoaded,
    getAttributionPixelGroupAggregateReports,
    getAttributionPixelGroupDebugReport,
    isAttributionPixelGroupDebugReportLoaded,
    attributionPixelGroupDebugReport,
  };
});
